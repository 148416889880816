@charset "utf-8";
@import 'base';
@import 'common';
@import '../fonts/iconfont.css';
//@import 'sanpingde';
/*BEGIN*/
#banner { width: 100%;
    .swiper-slide { overflow: hidden;
        a, img { display: block; width: 100%;}
    }
}
@media (min-width: 0px) and (max-width: 1200px) {
    #banner { width: 100%;
        .swiper-slide {
            img { width: 150%; margin-left: -25%;}
        }
    }
}
/*END*/
/*BEGIN*/
#header { position: absolute; left: 0; top: 0; width: 100%; z-index: 20;}
.header_h { padding: 16px 0 18px 0;
    img { display: block;}
}
.header_h_l { float: left;}
.header_h_r { float: right;}
.header_b { background: rgba(18, 73, 156, 0.9); border-radius: 10px; box-sizing: border-box; padding: 0 40px !important;
    > a { display: block; float: left;
        img { display: block;}
    }
    > ul { float: right; width: 540px; padding-top: 24px;
        li { float: left; width: 25%; text-align: right;
            a { display: block; float: right; line-height: 36px; font-size: 17px; color: #ffffff;
                &:hover { color: #ff0;}
            }
        }
    }
}
.header_menu_btn { display: none;}
@media (min-width: 0px) and (max-width: 1100px) {
    body { padding-top: 72px;
        &.btnShow {
            .header_menu_madel { display: block;}
            .header_b {
                > ul { display: block;}
            }
        }
    }
    #header { position: fixed; height: 72px; z-index: 30; background: rgba(18, 73, 156, 1);}
    .header_h { display: none;}
    .header_b { height: 72px; border-radius: 0; padding: 0 12px !important;
        > a { padding: 4px 0;
            img { height: 66px;}
        }
        > ul { display: none; position: fixed; top: 72px; left: 0; z-index: 18; bottom: 0; overflow: auto;
            width: 280px; box-sizing: border-box; padding: 10px 0; background: rgba(18, 73, 156, 1);
            li { width: 100%; text-align: left;
                a { width: 100%; line-height: 36px; border-bottom: 1px solid rgba(255, 255, 255, 0.05); box-sizing: border-box; padding: 5px 15px;
                    &:hover { color: #ff0;}
                }
            }
        }
    }
    .header_menu_btn { display: block; float: right; padding-top: 17px;
        a { float: left; width: 36px; height: 36px; line-height: 36px; text-align: center; border: 1px solid #fff; border-radius: 4px;
            .iconfont { color: #fff; font-size: 20px;}
            &:nth-child(2) { margin-left: 10px; background: #fff;
                .iconfont { color: rgba(18, 73, 156, 1); }
            }
        }
    }
    .header_menu_madel { display: none; position: fixed; top: 0; left: 0; height: 100%; width: 100%; background: rgba(0, 0, 0, 0.5); z-index: 10;}
}
/*END*/
/*BEGIN*/
#footer { overflow: hidden;}
.footer_h { background: #336abe;}
.footer_h_form { padding: 28px 0 18px 0;
    li { float: left; width: 24.65%; margin-right: 3%;
        div { background: #fff; box-sizing: border-box; padding: 0 25px;
            input { display: block; line-height: 50px; height: 50px; width: 100%; border: none; background: none; font-size: 16px;}
            @include inputcolor('#999999', '#333')
        }
        &:last-child { margin-right: 0; width: 17%;
            a { display: block; line-height: 50px; background: #e24c0c; text-align: center; font-size: 24px; color: #ffffff; font-weight: bold;}
        }
    }
}
.footer_b { padding: 30px 0 20px 0; background: #12499c;}
.footer_nav_lin { float: left;}
.footer_nav_links { float: left; margin-right: 30px; position: relative;
    h4 { white-space: nowrap; padding-right: 30px; background: url("../images/icon_01.png") no-repeat right center; font-size: 16px; color: #fff;}
    ul { position: absolute; bottom: 99%; left: 0; z-index: 10; width: 150px; max-height: 300px; overflow: auto; display: none;
        a { display: block; background: #fff; font-size: 14px; color: #000; padding: 4px 5px; border-bottom: 1px solid #ddd;
            @include ellipsiso;
            &:hover { background: #07224b; color: #fff;}
        }
    }
    &:hover {
        ul { display: block;}
    }
}
.footer_navs { float: right; width: 350px;
    li { float: left; width: 33.33%; text-align: center; font-size: 16px; line-height: 37px; text-align: right;
        a { color: #ffffff;
            &:hover { color: #ff0;}
        }
    }
}
.footer_nav { height: 75px;}
.footer_infos {
    > div { float: left;}
    .footer_infos1 { width: 20%;}
    .footer_infos2 { width: 60%; text-align: center; margin-top: -30px;}
}
.footer_logo { padding-bottom: 20px;
    img { display: block; margin: 0 auto;}
}
.footer_info_text { font-size: 14px; line-height: 30px;
    &, a { color: #ffffff;}
}
@media (min-width: 0px) and (max-width: 1100px) {
    .footer_h_form { padding: 12px 0;}
    .footer_b { padding: 20px 0;}
    .footer_nav { display: none;}
    .footer_infos {
        > div {}
        .footer_infos1 { width: 100%;
            img { display: block; margin: 0 auto; height: 100px;}
        }
        .footer_infos2 { width: 100%; text-align: center; margin-top: 0px;}
    }
    .footer_logo { padding-bottom: 15px; padding-top: 20px;
        img { max-width: 80%;}
    }
    .footer_info_text { font-size: 12px; line-height: 20px;
        &, a { color: #ffffff;}
    }
}
@media (min-width: 0px) and (max-width: 750px) {
    .footer_h_form {
        li { width: 48%; margin-right: 4%;
            &:nth-child(2n) { margin-right: 0;}
            &:nth-child(1), &:nth-child(2) { margin-bottom: 12px;}
            div { padding: 0 15px;
                input { line-height: 36px; height: 36px;}
            }
            &:last-child { margin-right: 0; width: 48%;
                a { line-height: 36px; font-size: 18px;}
            }
        }
    }
}
/*END*/
/*BEGIN*/
.sub_page_ban { overflow: hidden; width: 100%;
    img { display: block; width: 100%;}
}
.sub_content { padding: 60px 0;}
.sub_navigation { background: url("../images/nav_01.png") no-repeat center; background-size: 100% 100%;}
.sub_navigation_nav { float: left; height: 54px; background: url("../images/nav_02.png") no-repeat right top; background-size: auto 100%; box-sizing: border-box; padding: 7px 35px 0 15px;
    > div { border-left: 4px solid #fff; padding-left: 14px;
        em, span { display: block;}
        em { line-height: 28px; font-size: 26px; color: #ffffff; font-weight: bold;}
        span { line-height: 14px; font-size: 12px; color: #ffffff; text-transform: uppercase;}
    }
}
.sub_navigation_link { float: right; line-height: 54px; padding-right: 14px;
    img, div { display: inline-block; vertical-align: middle;}
    img { margin-right: 10px;}
    &, a { font-size: 14px; color: #ffffff;}
    a:hover {
        color: #ff0;
    }
}
.sub_nav { text-align: center; display: flex;justify-content: center; padding: 60px 0 50px 0; align-items: center;
    a { line-height: 34px; padding: 0 15px; border: 1px solid #12499c; font-size: 18px; color: #000000;
        &.on, &:hover { background: #12499c; color: #fff;}
    }
    span { height: 1px; width: 40px; background: #12499c;}
}
.sub_content_main { min-height: 360px; font-size: 16px; line-height: 2em; color: #010101;}
@media (min-width: 0px) and (max-width: 1400px) {
    .sub_page_ban {
        img { width: 150%; margin-left: -25%;}
    }
}
@media (min-width: 0px) and (max-width: 1100px) {
    .sub_content { padding: 20px 0;}
    .sub_navigation { background: none}
    .sub_navigation_nav { float: initial; margin: 0 auto; width: 200px; height: 50px; background: #12499c; padding: 7px 20px;
        > div { border-left: none; padding-left: 0; text-align: center;
            em { line-height: 22px; font-size: 20px;}
        }
    }
    .sub_navigation_link { display: none;}
    .sub_nav { padding: 30px 0;
        a { padding: 0 10px; font-size: 14px;}
        span { width: 15px;}
    }
}
/*END*/
/*BEGIN*/
.about_box { background: #f2f3f9; padding: 20px; border-radius: 30px 0 0 0; margin-top: 30px;
    img { max-width: 100%;}
}
.about_imgs {
    img { display: block; width: 100%;}
    .about_imgs1 {float: left; margin-top: 30px; width: 48%;}
    .about_imgs2 {float: right; margin-top: -60px; margin-right: -20px; width: 48%;}
}
.about_box_footer { padding: 30px 0 10px 0; border-top: 1px solid #ecb09a; margin-top: 20px;
    img { display: block; margin: 0 auto;}
}
@media (min-width: 0px) and (max-width: 1100px) {
    .about_box { padding: 20px 10px; border-radius: 10px; margin-top: 0;
        img { max-width: 100%;}
    }
    .about_imgs {
        img { display: block; width: auto; margin: 0 auto;}
        .about_imgs1 { width: 100%; margin-top: 30px; padding-bottom: 20px;}
        .about_imgs2 { width: 100%; margin-top: 0; margin-right: 0; padding-bottom: 20px;}
    }
    .about_box_footer { padding: 30px 0 10px 0; border-top: 1px solid #ecb09a; margin-top: 20px;
        img { display: block; margin: 0 auto;}
    }
}
/*END*/
/*BEGIN*/
.team_list {
    li { float: left; width: 23%; margin: 0 2.66% 26px 0;
        &:nth-child(4n) { margin-right: 0;}
        a { display: block;}
        img { display: block; width: 100%;}
        h3 { margin-top: 20px; height: 42px; line-height: 42px; border: 1px solid #12499c; padding: 0 10px; font-size: 16px; color: #333333; font-weight: normal; text-align: center;
            @include ellipsiso;
        }
        &:hover {
            h3 { font-weight: bold; color: #12499c;}
        }
    }
}
@media (min-width: 0px) and (max-width: 1100px) {
    .team_list {
        li { width: 48%; margin: 0 4% 20px 0;
            &:nth-child(2n) { margin-right: 0;}
            h3 { margin-top: 10px; height: 36px; line-height: 36px; padding: 0 5px; font-size: 14px;}
        }
    }
}
/*END*/
/*BEGIN*/
.page_box { text-align: center; padding-top: 30px;
    span, a { display: inline-block; vertical-align: top; line-height: 32px; border-radius: 16px; min-width: 32px; border: 1px solid #212b84; font-size: 16px; margin: 0 5px;}
    a {
        &.prev, &.next { padding: 0 20px; background: #212b84; color: #fff;}
        &.on { background: #212b84; color: #fff;}
    }
}
@media (min-width: 0px) and (max-width: 1100px) {
    .page_box { padding-top: 25px;
        span, a { line-height: 26px; border-radius: 12px; min-width: 26px; border: 1px solid #212b84; font-size: 14px; margin: 0 3px;}
        a {
            &.prev, &.next { padding: 0 10px;}
        }
    }
}
/*END*/
/*BEGIN*/
.text_content {
    img { max-width: 100%;}
}
@media (min-width: 0px) and (max-width: 1100px) {

}
/*END*/
/*BEGIN*/
.tc_table { width: 100%; text-align: center;
    tr {
        td { border: 1px solid #000000; font-size: 18px; color: #000000; padding: 15px 10px;}
    }
}
.tc_table_logo_header { background: #0756a0;
    img, em { display: inline-block; vertical-align: middle;}
    em { color: #fff; font-size: 20px; color: #ffffff; padding-left: 10px; font-weight: bold;}
}
.tc_table_hr { border: 1px rgba(18, 73, 156, 0.71) dashed; margin: 50px 0;}
@media (min-width: 0px) and (max-width: 1100px) {
    .tc_table {
        tr {
            td { font-size: 16px; padding: 10px 5px;}
        }
    }
    .tc_table_logo_header {
        em { font-size: 18px; padding-left: 5px;}
    }
    .tc_table_hr { margin: 20px 0;}
}
/*END*/
/*BEGIN*/
.signup_form_box { border: 1px solid #d5d5d5; border-radius: 14px; padding: 34px 125px 34px 0; max-width: 870px; margin: 0 auto;
    background: url("../images/logo_sg.png") no-repeat right bottom;
}
.signup_form { width: 100%;
    tr {
        td { padding-bottom: 9px;
            &:nth-child(1) { text-align: right; font-size: 18px; color: #000000; width: 190px; padding-right: 5px;}
            div { border: 1px solid #e6e6e6; background: #fbfbfb; padding: 0 12px;
                input, select {display: block; width: 100%; line-height: 42px; height: 42px; border: none; background: none; font-size: 18px; color: #333;}
            }
        }
    }
}
.signup_form_button { text-align: right; padding-top: 15px;
    button { display: inline-block; width: 120px; line-height: 46px; border: none; font-size: 18px; color: #fff; cursor: pointer;
        &:nth-child(1) { background: #e24c0c; margin-right: 15px;}
        &:nth-child(2) { background: #c7c7c7;}
    }
}
@media (min-width: 0px) and (max-width: 1100px) {
    .signup_form_box { padding: 20px 20px 20px 0; background-size: 200px auto;}
    .signup_form {
        tr {
            td {
                &:nth-child(1) { font-size: 14px; width: 120px;}
                div { padding: 0 10px;
                    input, select { line-height: 36px; height: 36px; font-size: 14px;}
                }
            }
        }
    }
    .signup_form_button { text-align: center; padding-top: 10px;
        button { width: 100px; line-height: 42px; font-size: 16px;}
    }
}
/*END*/
/*BEGIN*/
.signup2_map { float: left; display: block;
    img { display: block;}
}
.signup2_info { margin-left: 550px; font-size: 16px; color: #000000; line-height: 2.2em;}
.signup2_info_title {
    em { display: inline-block; background: #336abe; line-height: 26px; color: #fff; border-radius: 0 13px 13px 0; padding: 0 15px 0 12px;}
}
.signup2_submit { padding-top: 50px;}
.signup2_text { border: 1px solid #c5c3c3; min-height: 220px; border-radius: 10px;}
@media (min-width: 0px) and (max-width: 1100px) {
    .signup2_map { width: 100%;
        img { max-width: 100%;}
    }
    .signup2_info { margin-left: 0px; float: left; width: 100%; font-size: 14px; padding-top: 30px;}
    .signup2_submit { padding-top: 20px;}
}
/*END*/
/*BEGIN*/
.connect_box { margin-top: 60px; background: url("../images/connect_01.png") no-repeat center; background-size: 100% 100%;
    padding: 50px 60px 100px 60px;
}
.connect_right { float: right;
    a, img { display: block;}
}
.connect_left { float: left; width: 50%;}
.connect_left_header {
    div { line-height: 70px; font-size: 22px; color: #000000; font-weight: bold;}
    img { display: block;}
}
.connect_left_list { padding-top: 15px; font-size: 18px; line-height: 2em; color: #000000;
    img, em { display: inline-block; vertical-align: middle;}
    img { margin-right: 10px;}
}
@media (min-width: 0px) and (max-width: 1100px) {
    .connect_box { background: none; margin-top: 10px;
        padding: 0px;
    }
    .connect_right {width: 100%; padding: 50px 20px;
        img { max-width: 100%;}
    }
    .connect_left { width: 100%;}
    .connect_left_header {
        div { line-height: 50px; font-size: 18px; }
    }
    .connect_left_list { padding-top: 15px; font-size: 18px; line-height: 2em; color: #000000;
        img, em { display: inline-block; vertical-align: middle;}
        img { margin-right: 10px;}
    }
}
/*END*/
/*BEGIN*/
.news_list {
    li { margin-bottom: 35px;}
    a { display: block;
        img { display: block; float: left;}
        > div { margin-left: 265px; border: 1px solid #d4d4d4; padding: 6px 35px;
            > h3 { line-height: 34px; font-size: 18px; color: #000000;
                @include ellipsiso;
            }
            > div { font-size: 14px; color: #a4a4a4; padding-top: 10px;
                @include ellipsiss(2, 24);
            }
            > footer { line-height: 20px; font-size: 14px; color: #a4a4a4; padding-top: 5px;}
        }
    }
}
@media (min-width: 0px) and (max-width: 1100px) {
    .news_list {
        li { margin-bottom: 20px;}
        a {
            img { display: block; float: left; width: 120px;}
            > div { margin-left: 140px;padding: 0px; border: none;
                > h3 { line-height: 24px; font-size: 16px; color: #000000;
                    @include ellipsiso;
                }
                > div { font-size: 13px; color: #a4a4a4; padding-top: 4px;
                    @include ellipsiss(1, 20);
                }
                > footer { line-height: 20px; font-size: 14px; color: #a4a4a4; padding-top: 5px;}
            }
        }
    }
}
/*END*/
/*BEGIN*/
.news_details_title { font-size: 18px; line-height: 2em; color: #000000; font-weight: bold; text-align: center; padding: 0 0 12px 0;}
.news_details_infos { border-bottom: 1px solid #dcdcdc; border-top: 1px solid #dcdcdc; font-size: 12px; line-height: 30px; text-align: center;
    &, a { color: #a0a0a0;}
}
.news_details_cons { font-size: 14px; color: #575757; line-height: 2em; padding: 20px 0;}
@media (min-width: 0px) and (max-width: 1100px) {
    .news_details_title { line-height: 1.5em; }
}
/*END*/
/*BEGIN*/
.sub_updown { padding-top: 20px;
    a { display: block; padding: 0 30px; line-height: 36px; color: #fff; background: #0757a0; font-size: 14px;}
    .sub_updown_up { float: left;}
    .sub_updown_down { float: right;}
}
@media (min-width: 0px) and (max-width: 1100px) {

}
/*END*/
/*BEGIN*/
.trainees_list {
    li { float: left; width: 31.41%; margin: 0 2.88% 26px 0;
        &:nth-child(3n) { margin-right: 0;}
        div { position: relative;}
        em { display: none; position: absolute; top: 0; left: 0; width: 100%; height: 100%; background: url("../images/trainees_hover.png") no-repeat center; background-size: 100% 100%;}
        a { display: block;}
        img { display: block; width: 100%;}
        h3 { margin-top: 20px; height: 64px; line-height: 64px; border: 1px solid #e4e4e4; padding: 0 10px; font-size: 18px; color: #333333; font-weight: bold; text-align: center;
            box-shadow: 0 5px 10px #ddd;
            @include ellipsiso;
        }
        &:hover {
            h3 { font-weight: bold; color: #12499c;}
            em { display: block;}
        }
    }
}
@media (min-width: 0px) and (max-width: 1100px) {
    .trainees_list {
        li { width: 48%; margin: 0 4% 20px 0;
            &:nth-child(3n) { margin-right: 4%;}
            &:nth-child(2n) { margin-right: 0;}
            h3 { margin-top: 10px; height: 36px; line-height: 36px; padding: 0 5px; font-size: 14px;}
        }
    }
}
/*END*/
/*BEGIN*/
.score_box { padding: 35px; background: #f0f7fc; border-radius: 14px;}
.score_header { background: url("../images/score_02.png") no-repeat center;
    img { display: block; margin: 0 auto;}
}
.score_form { text-align: center; padding: 80px 0 10px 0;
    em, div { display: inline-block;}
    em { line-height: 44px; font-size: 18px; color: #000000; vertical-align: top;}
    div {
        input { display: block; width: 400px; height: 42px; line-height: 42px; border: 1px solid #b0b3b5; padding: 0 20px; font-size: 18px; color: #959595;}
    }
}
.score_btn { text-align: center;
    a { display: inline-block; width: 118px; line-height: 48px; font-size: 18px; color: #ffffff;
        &:nth-child(1) { background: #12499c;}
        &:nth-child(2) { background: #e24c0c;}
    }
}
.score_links_title { text-align: center; line-height: 40px; font-size: 28px; color: #000000; margin: 74px 0 48px 0; background: url("../images/score_02.png") no-repeat center;}
.score_links { display: flex; background: url("../images/score_03.png") no-repeat center; width: 530px; margin: 0 auto; justify-content: space-between;
    flex-wrap: wrap;
    a { width: 43%; background: #336abe; color: #fff; text-align: center; border: 1px solid #ffffff; border-radius: 10px; font-size: 16px; line-height: 24px;
        padding: 12px 0;
        &:nth-child(1), &:nth-child(2) { margin-bottom: 24px;}
        &:hover { background: #e24c0c;}
    }
}
@media (min-width: 0px) and (max-width: 1100px) {
    .score_box { padding: 15px; border-radius: 10px; margin-top: -30px;}
    .score_header {
        img { height: 40px;}
    }
    .score_form { padding: 30px 0 20px 0;
        em { line-height: 40px; font-size: 14px;}
        div {
            input { width: 180px; height: 38px; line-height: 38px; padding: 0 10px; font-size: 14px;}
        }
    }
    .score_btn {
        a { width: 118px; line-height: 40px; font-size: 16px; color: #ffffff;}
    }
    .score_links_title { font-size: 24px; margin: 30px 0 20px 0; }
    .score_links { background: none; width: 100%;
        a { width: 48%; font-size: 14px; line-height: 22px;
            &:nth-child(1), &:nth-child(2) { margin-bottom: 4%;}
        }
    }
}
/*END*/
/*BEGIN*/
.score_search_box { padding: 0 35px 35px 35px; background: #f0f7fc; border-radius: 14px;}
.score_search_title { text-align: center; font-weight: bold; line-height: 80px; font-size: 30px; background: url("../images/score_02.png") no-repeat center;}
.score_search_list { width: 100%;
    tr {
        th { background: #12499c; color: #fff; font-size: 16px; line-height: 46px; white-space: nowrap; border-right: 1px solid rgba(255, 255, 255, 0.1); font-weight: normal;
            &:last-child { border-right: none;}
        }
        td { border: 1px solid #d4d4d4; text-align: center; font-size: 16px; color: #000000; line-height: 22px; padding: 10px 5px; background: #fff;
            a {
                &.confirm_ok { color: #12499c;}
                &.confirm_no { color: red;}
                &.score_search_view { color: #e24c0c;}
                &.score_search_com1, &.score_search_com2 { display: block; width: 90px; line-height: 28px; font-size: 16px; color: #ffffff; margin: 0 auto;}
                &.score_search_com1 { background: #b8b8b8;}
                &.score_search_com2 { background: #e24c0c; cursor: pointer;}
            }
        }
        &.table_sub_title {
            td { background: #e3e3e3;}
        }
    }
}
@media (min-width: 0px) and (max-width: 1100px) {
    .score_search_box { padding: 10px 12px;}
    .score_search_title { line-height: 70px; font-size: 28px;}
    .score_search_list {
        tr {
            th { font-size: 14px; line-height: 20px; white-space: normal; padding: 12px 0;}
            td { font-size: 14px; line-height: 20px; padding: 8px 1px;
                a { white-space: nowrap; display: inline-block;}
            }
        }
    }
}
/*END*/
/*BEGIN*/
.score_search_text { font-size: 12px; line-height: 24px; color: #ff0000; padding: 10px 0;}
.score_details_btn {
    a { display: block; text-align: center; font-size: 18px; color: #fff; font-weight: bold; background: #12499c; line-height: 80px;}
}
@media (min-width: 0px) and (max-width: 1100px) {
    .score_details_btn {
        a { line-height: 60px;}
    }
}
/*END*/
/*BEGIN*/
.index_menu_list { padding: 55px 0;
    li { float: left; text-align: center; width: 23.04%; margin: 0 2.61% 0 0; background: #fff;
        &:nth-child(4n) { margin-right: 0;}
        a { display: block; padding: 38px 0 27px 0;
            img { display: block; margin: 0 auto; height: 68px;}
            h3 { margin-top: 17px; line-height: 35px; font-size: 24px; color: #012756;}
            em { display: block; line-height: 28px; font-size: 20px; color: #999999; text-transform: uppercase;}
            &:hover { background: #ecebeb;}
        }
    }
}
@media (min-width: 0px) and (max-width: 1100px) {
    .index_menu_list { padding: 12px 0;
        li { width: 48%; margin: 0 4% 4% 0;
            &:nth-child(2n) { margin-right: 0;}
            a { display: block; padding: 20px 0 25px 0;
                img { height: 40px;}
                h3 { margin-top: 12px; line-height: 30px; font-size: 20px;}
                em { line-height: 26px; font-size: 14px; white-space: nowrap;}
            }
        }
    }
}
/*END*/
/*BEGIN*/
.index_t2_box { background: #fff; padding: 40px 0;}
#newsSwiper { float: left; width: 54.29%; position: relative;
    .swiper-slide {
        a, img { display: block; width: 100%;}
        a { position: relative;
            h3 { position: absolute; bottom: 0; left: 0; width: 100%; background: rgba(0, 0, 0, 0.5); box-sizing: border-box; padding: 0 20px;
                line-height: 60px; font-size: 22px; color: #ffffff; font-weight: normal;
                @include ellipsiso;
            }
        }
    }
    .swiper-pagination { position: absolute; right: 20px; left: auto; width: auto;
        .swiper-pagination-bullet { height: 28px; line-height: 28px; font-size: 16px; font-weight: bold; background: none !important; color: #fff; opacity: 1 !important;
            border-bottom: 3px solid transparent; border-radius: 0 !important; width: 14px;
            &.swiper-pagination-bullet-active { border-color: #dc0600; color: #dc0600;}
        }
    }
}
.news_tab { float: right; width: 43.12%;}
.news_tab_header {
    ul { line-height: 54px; height: 54px; border-bottom: 1px solid #295ba6;
        li { position: relative; float: left; font-size: 28px; color: #333333; padding: 0 14px; cursor: pointer;
            &:after { content: ' '; display: block; position: absolute; right: 0; top: 50%; width: 1px; height: 18px; background: #dcdada; margin-top: -9px;}
            &:last-child {
                &:after { display: none;}
            }
            &.on { color: #0f5097;}
        }
    }
}
.news_tab_main {
    ul { display: none; padding-top: 12px;
        li { height: 46px; line-height: 46px; overflow: hidden;
            a { display: block; padding-left: 14px; background: url("../images/icon_02.png") no-repeat left center;
                em { display: block; float: right; font-size: 16px; color: #999999;}
                h3 { margin-right: 100px; font-size: 16px; color: #333333; font-weight: normal;
                    @include ellipsiso;
                }
                &:hover { background: url("../images/icon_03.png") no-repeat left center;
                    h3 { color: #295ba6;}
                }
            }
        }
    }
}
@media (min-width: 0px) and (max-width: 1100px) {
    .index_t2_box { padding: 30px 0;}
    #newsSwiper { width: 100%;
        .swiper-slide {
            a {
                h3 { padding: 0 15px; line-height: 40px; font-size: 18px;}
            }
        }
        .swiper-pagination { display: none;}
    }
    .news_tab { float: right; width: 100%;}
    .news_tab_header { padding-top: 20px;
        ul { line-height: 42px; height: 43px;
            li { font-size: 18px;}
        }
    }
    .news_tab_main {
        ul { padding-top: 8px;
            li { height: 38px; line-height: 38px;}
        }
    }
}
/*END*/
/*BEGIN*/
$thHeight: 40px;
.teachers_header { padding-top: 30px; padding-bottom: 20px;
    &, div, em, h2 { height: $thHeight; line-height: $thHeight;}
    h2 { float: left;
        a { font-size: 28px; color: #222222;}
    }
    em { display: block; float: right;
        a { font-size: 16px; color: #999999;
            &:hover { color: #295ba6;}
        }
    }
    div { margin: 0 70px 0 130px; background: url("../images/icon_04.png") no-repeat left center;}
}
.teachers_list_box { box-sizing: border-box; padding: 14px 16px; border: 1px solid #e9e9e9;}
#teachersList {
    .swiper-slide { position: relative;
        > div { padding: 8px; border: 1px solid transparent;}
        &:hover > div{
            border: 1px solid rgba(18, 73, 156, 0.2); background: rgba(18, 73, 156, 0.03);
        }
        a { position: absolute; top: 0; left: 0; height: 100%; width: 100%; z-index: 3;}
        header { padding-bottom: 18px;
            em { display: block; height: 70px; width: 70px; text-align: center; line-height: 70px; float: left; font-size: 22px; color: #ffffff; font-weight: bold;
                background: url("../images/teachers_icon.png") no-repeat center; background-size: 100% 100%;
            }
            div { margin-left: 84px;
                strong, span { display: block;}
                strong { line-height: 38px; font-size: 24px; color: #333333;}
                span { line-height: 32px; font-size: 18px; color: #333333;}
            }
        }
        main {
            img { display: block; width: 100%;}
        }
        footer {
            h3 { font-size: 14px; color: #666666; font-weight: normal; margin: 12px 0;
                @include ellipsiss(2, 24);
            }
            div { font-size: 14px; line-height: 24px; color: #cf0a17; text-align: right;
                em, img { display: inline-block; vertical-align: middle;}
            }
        }
    }
}
@media (min-width: 0px) and (max-width: 1100px) {
    $thHeight: 30px;
    .teachers_header { padding-top: 20px; padding-bottom: 10px;
        h2 {
            a { font-size: 22px; }
        }
        em {
            a { font-size: 14px;}
        }
        div { margin: 0 70px 0 100px; }
    }
    .teachers_list_box { padding: 10px;}
    #teachersList {
        .swiper-slide {
            header { padding-bottom: 15px;
                em { height: 50px; width: 50px; line-height: 50px; font-size: 14px;}
                div { margin-left: 60px;
                    strong { line-height: 24px; font-size: 16px; }
                    span { line-height: 24px; font-size: 12px;}
                }
            }
            footer {
                h3 { margin: 10px 0;}
                div { line-height: 20px; font-size: 12px;
                    img { width: 20px;}
                }
            }
        }
    }
}
/*END*/
/*BEGIN*/
.index_trainees_main { padding: 42px 0 75px 0;}
.in_trainees_header { height: 54px; margin-bottom: 35px; border-left: 4px solid #12499c; padding-left: 10px;
    > span, > strong { display: block;}
    > span { line-height: 18px; font-size: 16px; color: #999999; text-transform: uppercase;}
    > strong { line-height: 36px; font-size: 28px; color: #222222;}
    .traineesList-pn { float: right; padding-top: 5px; position: relative; z-index: 5;
        div { float: left; margin-left: 24px; cursor: pointer;}
    }
}
#traineesList {
    .swiper-slide { text-align: center;
        a {display: block;
            img { display: block; width: 100%;}
            h3 { line-height: 80px; font-size: 18px; color: #333333;
                @include ellipsiso;
            }
            div {
                em { display: inline-block; vertical-align: top; width: 150px; line-height: 34px; font-size: 14px; color: #fff; background: #12499c;}
            }
            &:hover {
                h3 { color: #12499c;}
            }
        }
    }
}
@media (min-width: 0px) and (max-width: 1100px) {
    .index_trainees_main { padding: 20px 0;}
    .in_trainees_header { height: 48px; margin-bottom: 15px;
        > span { line-height: 16px; font-size: 14px;}
        > strong { line-height: 32px; font-size: 22px;}
        .traineesList-pn { padding-top: 10px;
            div { margin-left: 10px;
                img { display: block; height: 30px;}
            }
        }
    }
    #traineesList {
        .swiper-slide { text-align: center;
            a {
                h3 { line-height: 50px; font-size: 16px;}
                div {
                    em { width: 100px; line-height: 32px; }
                }
            }
        }
    }
}
/*END*/
/*BEGIN*/
.index_video_box { padding: 30px 0 40px 0; background: #fff;}
.index_video_left { float: left; width: 53.43%;
    a { position: relative;
        &, > img { display: block; width: 100%;}
        div { position: absolute; top: 0; left: 0; width: 100%; height: 100%; background: url('../images/video_04.png') no-repeat center; background-size: 100% 100%;
            img { position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);}
            h3 { position: absolute; left: 0; bottom: 0; width: 100%; line-height: 60px; box-sizing: border-box; padding: 0 20px; font-size: 20px; color: #ffffff; text-align: center; font-weight: normal;
                @include ellipsiso;
            }
        }
    }
}
.index_video_right { margin-left: 55.15%;
    li { width: 48.6%; float: left; margin: 0 2.8% 0 0;
        &:nth-child(2n) { margin-right: 0;}
        a { position: relative;
            &, > img { display: block; width: 100%;}
            div { position: absolute; top: 0; left: 0; width: 100%; height: 100%; background: rgba(0, 0, 0, 0.4);
                img { position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);}
            }
            h3 { text-align: center; line-height: 46px; font-size: 16px; color: #666666; font-weight: normal;
                @include ellipsiso;
            }
        }
    }
}
@media (min-width: 0px) and (max-width: 1100px) {
    .index_video_box { padding: 0 0 10px 0; }
    .index_video_left { width: 100%;
        a {
            div {
                img { height: 50px;}
                h3 { line-height: 50px; padding: 0 10px; font-size: 18px;}
            }
        }
    }
    .index_video_right { margin-left: 0%; float: left; margin-top: 2.8%;
        li { width: 48.6%; float: left; margin: 0 2.8% 0 0;
            &:nth-child(2n) { margin-right: 0;}
            a { position: relative;
                &, > img { display: block; width: 100%;}
                div { position: absolute; top: 0; left: 0; width: 100%; height: 100%; background: rgba(0, 0, 0, 0.4);
                    img { position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);}
                }
                h3 { text-align: center; line-height: 46px; font-size: 16px; color: #666666; font-weight: normal;
                    @include ellipsiso;
                }
            }
        }
    }
}
/*END*/
/*BEGIN*/
.index_about_box { padding: 34px 0 50px 0;}
.index_about_con_left { float: left; width: 56.48%;
    img { width: 100%; display: block;}
}
.index_about_con_right { margin-left: 59.38%;}
.index_about_con_right1 { padding-top: 14px;
    h1 { font-size: 28px; line-height: 60px; color: #12499c;}
    em { display: block; width: 78px; height: 5px; background: #12499c;}
}
.index_about_con_right2 { font-size: 16px; line-height: 2.2em; color: #666666; margin: 30px 0;}
.index_about_con_right3 { font-size: 14px; line-height: 24px; color: #cf0a17;
    em, img { display: inline-block; vertical-align: middle;}
}
@media (min-width: 0px) and (max-width: 1100px) {
    .index_about_box { padding: 30px 0;}
    .index_about_con_left { float: left; width: 100%;}
    .index_about_con_right { margin-left: 0; float: left; padding-top: 10px;}
    .index_about_con_right1 { padding-top: 0;
        h1 { font-size: 20px; line-height: 40px; text-align: center;}
        em { margin: 0 auto;}
    }
    .index_about_con_right2 { font-size: 14px; margin: 15px 0 0 0;}
    .index_about_con_right3 { text-align: center;}
}
/*END*/
/*BEGIN*/
#honorListBox { position: relative; padding: 0 56px; margin-top: 30px;}
.honorList_next, .honorList_prev { position: absolute; top: 50%;transform: translate(0, -90%); cursor: pointer;
    img { display: block;}
}
.honorList_next { right: 0;}
.honorList_prev { left: 0;}
#honorList {
    .swiper-slide { text-align: center;
        a, img { display: block; width: 100%;}
        h3 { font-size: 16px; color: #666666; font-weight: normal; line-height: 40px;
            @include ellipsiso;
        }
    }
}
@media (min-width: 0px) and (max-width: 1100px) {
    #honorListBox { padding: 30px 25px 0 25px; margin-top: 30px; border-top: 1px solid #ddd;}
    .honorList_next, .honorList_prev { transform: translate(0, -60%);
        img { width: 15px;}
    }
    #honorList {
        .swiper-slide {
            h3 { font-size: 16px; line-height: 36px;}
        }
    }
}
/*END*/
#toTop { display: block; position: fixed; right: 10px; bottom: 10px; z-index: 200; border-radius: 50%; height: 40px; width: 40px; text-align: center; line-height: 40px; background: #12499c;
    .iconfont { color: #fff; font-size: 20px;}
}
