//圆角兼容
@mixin borderRadius($num) {
    -webkit-border-radius: $num;
    -moz-border-radius: $num;
    border-radius: $num;
}
//透明兼容
@mixin opacity($num) {
    filter:alpha(opacity=$num*100);
    opacity:#{$num};
}
//定位 - 没有值写null
@mixin position($num, $top, $right, $bottom, $left) {
    position:$num;
    left:$left +px;
    right:$right +px;
    bottom:$bottom +px;
    top:$top +px;
}
//宽高
@mixin wh($num1, $bum2){
    width:$num1 + px;
    height:$bum2 + px;
}
//大体大小 - 字体颜色
@mixin fc($fontsize, $color){
    font-size:$fontsize +px;
    color:$color;
}
//阴影兼容
@mixin boxShaow($num){
    -webkit-box-shadow:$num;
    -moz-box-shadow:$num;
    box-shadow:$num;
}
//定位居中显示
@mixin opCenter($num, $width, $height){
    //定位居中显示
    position:$num;
    left:50%;
    top:50%;
    margin-left:-$width/2 +px;
    margin-top:-$height/2 +px;
}
//单行截子符
@mixin ellipsiso{
    display: block;
    overflow: hidden;
    white-space: nowrap;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
}
//多行截字符
@mixin ellipsiss($num1,$num2){
    line-height:$num2 + px;
    overflow: hidden;
    display: -webkit-box;
    height: $num1*$num2 + px;
    -webkit-line-clamp: $num1;
    -webkit-box-orient: vertical;
}
//input/textarea的placeholder及输入
@mixin inputcolor($color1:#63758a,$color2:#b7c0cc){
    & input,& textarea{ color:$color1;}
    & input::-webkit-input-placeholder{ color: $color2;}
    & input:-moz-placeholder{ color: $color2;}
    & input::-moz-placeholder{ color: $color2;}
    & input:-ms-input-placeholder{ color: $color2;}
    & textarea::-webkit-input-placeholder{ color: $color2;}
}
//渐变色 左右
@mixin gradient($bg1:#5cc86f,$bg2:#5cc86f){
    background: $bg1;
    background: -webkit-gradient(linear, left top, right top, from($bg1), to($bg2));
    background: -webkit-linear-gradient(left, $bg1, $bg2);
    background: -moz-linear-gradient(left, $bg1, $bg2);
    background: -o-linear-gradient(left, $bg1, $bg2);
    background: linear-gradient(to right, $bg1, $bg2);
}
//行内块级兼容
@mixin displayInlineBlock(){
    display:inline-block; *zoom:1; *display:inline;
}
//hover -> background
@mixin hovers($color1, $color2) {
    background:$color1;
    &:hover{
        background:$color2;
    }
}
//hover缓动时需要
@mixin Easing($num){transition: all $num+s ease-in-out;}
//放大缩小
@mixin smallToBig($time1:0.6,$time2:0,$num:1.05){ transition: all $time1+s $time2+s;
    &:hover{transform: scale($num);}
}

