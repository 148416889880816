/*样式初始化   开始*/
body,h1,h2,h3,h4,h5,h6,hr,p,blockquote,dl,dt,dd,ul,ol,li,pre,fieldset,button,input,textarea,th,td{margin:0; padding:0; outline:none;}
a:focus{-moz-outline-style:none;outline:none;}
body,button,input,select,textarea,code,kbd,pre,samp,tt{ font-family:"Microsoft YaHei",Tahoma,Helvetica,Arial,"\5b8b\4f53",sans-serif;}
//body,button,input,select,textarea,code,kbd,pre,samp,tt{
//    font-family: "メイリオ", Meiryo, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
//}
address,cite,dfn,em,var{font-style:normal;}
small{font-size:12px;}
ul,ol{list-style:none;}
a{text-decoration:none;}
abbr[title],acronym[title]{border-bottom:1px dotted; cursor:help;}
legend{color:#000;}
fieldset,img{border:none;}
button,input,select,textarea{font-size:100%;font-family: "Microsoft YaHei";}
table{border-collapse:collapse; border-spacing:0;}
hr{border:none; height:1px;}
body{ font-size:12px; line-height:26px; white-space: normal; word-break: break-word;}
/*样式初始化   结束*/
/*公用样式   开始*/
.clearfix:after{content:"."; display:block; height:0; clear:both; visibility:hidden;}
.clearfix{display:inline-block; display:block; zoom:1;}
.pull_left{float:left;}
.pull_right{float:right;}
.nopull{float:none;}
.w1280{ max-width:1280px; margin:0 auto; padding-left: 12px !important; padding-right: 12px !important;}
.w1200{ max-width:1200px; margin:0 auto; padding-left: 12px !important; padding-right: 12px !important;}
.w1100{ max-width:1100px; margin:0 auto; padding-left: 12px !important; padding-right: 12px !important;}
.w1000{ max-width:1000px; margin:0 auto; padding-left: 12px !important; padding-right: 12px !important;}
.line1 {display: block;overflow: hidden;white-space: nowrap;-o-text-overflow: ellipsis;text-overflow: ellipsis;}
.line2 {overflow: hidden;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;}
.line3 {overflow: hidden;display: -webkit-box;-webkit-line-clamp: 3;-webkit-box-orient: vertical;}
.line4 {overflow: hidden;display: -webkit-box;-webkit-line-clamp: 4;-webkit-box-orient: vertical;}
.line5 {overflow: hidden;display: -webkit-box;-webkit-line-clamp: 5;-webkit-box-orient: vertical;}
.line6 {overflow: hidden;display: -webkit-box;-webkit-line-clamp: 6;-webkit-box-orient: vertical;}
/*公用样式   结束*/
